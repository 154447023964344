import type { LoaderFunctionArgs } from "@remix-run/node";
import { Outlet } from "@remix-run/react";
import { Card } from "~/components/ui/Card";
import { requireAuth } from "~/utils/auth-utils/requireAuth.server";

export const twoFAVerificationType = "2fa";
export const twoFAVerifyVerificationType = "2fa-verify";

export default function TwoFactorLayout() {
  return (
    <div className="flex min-h-full flex-col justify-center p-4 xl:p-12">
      <div className="mx-auto w-full max-w-2xl">
        <Card>
          <Outlet />
        </Card>
      </div>
    </div>
  );
}

export async function loader({ request }: LoaderFunctionArgs) {
  await requireAuth(request, { requireValidSubscription: false });
  return null;
}
